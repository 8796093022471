export const WORDS = [
  'melon',
  'count',
  'white',
  'snack',
  'smile',
  'album',
  'hands',
  'music',
  'lower',
  'ramen',
  'chest',
  'sleep',
  'water',
  'years',
  'doors',
  'proud',
  'shiny',
  'valor',
  'hopes',
  'heart',
  'right',
  'amber',
  'night',
  'photo',
  'phone',
  'llama',
  'squat',
  'vegas',
  'sound',
  'rouge',
  'shake',
  'vibes',
  'peace',
  'cover',
  'chill',
  'plane',
  'shirt',
  'asian',
  'three',
  'lyric',
  'shows',
  'noise',
  'dance',
  'shock',
  'rogue',
  'salad',
  'world',
  'bobas',
  'watch',
  'other',
  'video',
  'walls',
  'fruit',
  'alien',
  'tacos',
  'track',
  'ready',
  'train',
  'pizza',
  'songs',
  'laugh',
  'tours',
  'eagle',
  'happy',
  'brass',
  'mixes',
  'voice',
  'drink',
  'party',
  'black',
  'gamer',
  'spice',
  'owata',
  'taste',
  'jelly',
  'heals',
  'skate',
  'awake',
  'again',
  'spawn',
  'juice',
  'blind',
  'hyped',
  'tears',
  'learn',
  'floor',
  'ideas',
  'shine',
  'first',
  'phase',
  'spoon',
  'chase',
  'drums',
  'crazy',
  'edits',
  'break',
  'snacc',
  'chalk',
  'storm',
  'drown',
  'kirby',
  'lungs',
  'chats',
  'board',
  'stair',
  'scene',
  'catch',
  'stand',
  'loved',
  'jamba',
  'fight',
  'thank',
  'tease',
  'gummy',
  'honey',
  'sober',
  'words',
  'demon',
  'scare',
  'tough',
  'kicks',
  'dream',
  'cloud',
  'adore',
  'raids',
  'money',
  'brain',
  'space',
  'beats',
  'stars',
  'grass',
  'sings',
  'think',
  'swear',
  'sense',
  'andro',
  'guide',
  'write',
  'twist',
  'games',
  'drunk',
  'shout',
  'adult',
  'wanna',
  'tight',
  'virgo',
  'youth',
  'drive',
  'group',
  'speak',
  'jacks',
  'super',
  'throw',
  'funny',
  'cross',
  'lemon',
  'sugar',
  'shoes',
  'sweet',
  'apple',
  'climb',
  'steel',
  'enjoy',
  'story',
  'point',
  'merch',
  'place',
  'birbs',
  'scars',
  'meows',
  'light',
  'movie',
]
